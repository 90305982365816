@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.defaultUserFields {
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.phoneNumberInput{
  margin-top: 30px;

  & .invalidPhoneValue{
    color: red;
    margin: 0;
    font-size: 13px;
    font-weight: 700;
  }
}

.phoneNumberInput{
  margin-top: 30px;

  & .verifyText{
    text-align: right;
    color: #9238ff;
    cursor: pointer;
    font-size: 16px;
    float: right;
    position: relative;
    top: 5px;
    display: flex;
    align-items: unset;

    & svg{
      position: relative;
      top: 1.5px;
      left: 2px;
    }
  }

  & .invalidPhoneValue{
    color: red;
    margin: 0;
    font-size: 13px;
    font-weight: 700;
  }
}

.optModal{
  display: flex;
  align-items: end;
  gap: 1em;

  @media (max-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 20px;
    display: block;

    &  button{
      margin-top: 2em;
    }
  }
  
  & button{
    min-height: 48px;
    background-color: #9238ff;
  }
}